<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table nowrap">
                <thead>
                    <tr>
                        <th>Doctor</th>
                        <th>Invoice no</th>
                        <th>Invoice Date</th>
                        <th>Patient ID</th>
                        <th>Patient Name</th>
                        <th>Patient Mobile No</th>
                        <th>Admission No</th>
                        <th class="text-right">Amount</th>
                        <th class="text-right">Return Amount</th>
                        <th class="text-right">Net Collection</th>
                        <th v-for="(column, index) in dynamicColumns" :key="index" class="text-right max-w-100">
                            {{column}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(child, childIndex) in props.sales" :key="childIndex">
                        <th>{{ child.service_resource_name }}</th>
                        <th>{{ child.bill_number }}</th>
                        <th>{{ child.date }}</th>
                        <th>{{ child.serial_no }}</th>
                        <th>{{ child.full_name }}</th>
                        <th>{{ child.mobile_no }}</th>
                        <th>{{ child.admission_no }}</th>
                        <th class="text-right">{{ commaFormat(child.subtotal_amount) }}</th>
                        <th class="text-right">{{ commaFormat(child.sale_return_masters_sum_paid_amount) }}</th>
                        <th class="text-right">{{ commaFormat(child.paid_amount-child.sale_return_masters_sum_paid_amount) }}</th>
                        <td v-for="(column, key) in dynamicColumns" :key="key" class="text-right">
                            {{ commaFormat(child[key]) }}
                        </td>
                    </tr>
                    <tr>
                        <th colspan="7" class="text-right">Total ({{ props.sales.length }})</th>
                        <th class="text-right">{{ commaFormat(getTotalAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalReturnAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalPaidAmount(props.sales)-getTotalReturnAmount(props.sales)) }}</th>
                    </tr>
                </tbody>
            </table>
          <p v-if="!props.sales.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
    sales: Array,
    dynamicColumns: {
        type: Object,
        default: {}
    }
})

const { commaFormat } = figureFormatter();

const getTotalAmount = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.subtotal_amount, 0);
}

const getTotalPaidAmount = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.paid_amount, 0);
}
const getTotalReturnAmount = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.sale_return_masters_sum_paid_amount, 0);
}
</script>

<style scoped>
    .nowrap{
        white-space: nowrap;
    }
</style>
