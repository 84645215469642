<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Doctor Incentive Detailed Report"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getSalesReportGeneral"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Created By:</label>
          <v-select placeholder="Created By" :options="contactList" label="name"
              :reduce="(name) => name.id" v-model="updated_by" />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Bill type: </label>
          <v-select
              multiple
              placeholder="Select Bill Type"
              v-model="billType"
              :options="billTypeList"
              label="label"
              :reduce="label => label.value"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="d-flex justify-content-end px-2 mb-2 gap-1">
      <button
        @click="exportTable"
        class="btn btn-secondary btn-sm btn-download-invoice waves-effect"
      >
        Export
      </button>
    </div>

    <div class="col-12 px-2">
      <IncentiveDetailedReportTable 
        :sales="sales"
        :dynamicColumns="dynamicColumns"
      />
    </div>
    <div class="mb-2"></div>

    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {useRoute, useRouter} from 'vue-router';
import DateQuerySetter from '@/components/atom/DateQuerySetter';
import IncentiveDetailedReportTable from "@/components/molecule/company/report/IncentiveDetailedReportTable.vue";
import handleCBusinesses from "@/services/modules/businesses";
import handleHospitalReport from "@/services/modules/hospital/report";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import Pagination from "@/components/atom/Pagination.vue";
import {useStore} from "vuex";
import handleCompany from "@/services/modules/company";
import pdfPrinter from '@/services/utils/pdf/dailySalesReport';
import handleHospital from "@/services/modules/hospital";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import handleInventory from "@/services/modules/inventory";
import filterNullFromObject from "@/services/utils/filterNullFromObject";
import useReport from '@/services/modules/dashboard/report.js'

const {fetchHome} = handleInventory()
const { fetchContacts } = handleImportTracker();
const {fetchServiceList} = handleHospital()
const {fetchBusinessList} = handleCBusinesses()
const {fetchSalesReportGeneral} = handleHospitalReport()
const { fetchCompanyInfo } = handleCompany();
const { exportToPDF } = pdfPrinter();
const { fetchIncentiveDetailedReport } = useReport()

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const business = ref([])
const contactList = ref([])
const billTypeList = ref([])
let offset = ref(10)
const sales = ref([])
const pdfSales = ref([]);
const human_resource_id = ref(null)
const updated_by = ref(null)
const billType = ref(null)
const companyInfo = ref({})
const isPaginated = ref(true)
const payload = ref({
  'billType': null,
  'businessName': null,
  'consultantName': null,
  'createdBy': null
})
const dynamicColumns = ref(null);

//computed
const companyId = computed(() => route.params.companyId);
const startDate = computed(() => route.query.start);
const endDate = computed(() => route.query.end);
const page = computed(() => route.query.page || 1);
const token = computed(() => localStorage.getItem('token'));

const getQuery = () => {
    let query = '?company_id=' + companyId.value
    if (!!billType.value && billType.value.length > 0) {
        query += '&sale_type=' + billType.value
    }

    if (!!human_resource_id.value) {
        query += '&service_resource_id=' + human_resource_id.value
    }

    if (!!updated_by.value) {
        query += '&updated_by=' + updated_by.value
        payload.value.createdBy = contactList.value.find(el => el.id == updated_by.value).name
    }

    if (startDate.value && endDate.value) {
        query += '&start_date=' + startDate.value
        query += '&end_date=' + endDate.value
    }
    
    if (page.value) query += '&page=' + page.value
    if(isPaginated.value) query += '&is_paginated=1'
    query += '&offset=' + offset.value
    return query
};
const getSalesReportGeneral = async (pagination = true) => {

    isPaginated.value = pagination;

    await fetchIncentiveDetailedReport(getQuery()).then(({status, data}) => {
        if (!status) {
            sales.value = []
            pdfSales.value = [];
            return resetPagination({})
        }

        sales.value = data.data;
        dynamicColumns.value = data.dynamic_columns
        setPagination(data.pagination)

    }).catch((err) => {
        loading.value = false
    }).finally(() => {
        loading.value = false
    })
};


const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};
const resetPagination = () => {
    store.commit('resetPagination')
};


const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getSalesReportGeneral()
}

const exportTable = () => {

    const queryObject = {
        company_id: companyId.value,
        start_date: startDate.value || '',
        end_date: endDate.value || '',
        sale_type: billType.value,
        service_resource_id: human_resource_id.value,
        updated_by: updated_by.value,
        _token: token.value,
        is_export: 1
    }

    const queryString = new URLSearchParams(filterNullFromObject(queryObject)).toString();

    let url = `${process.env.VUE_APP_BASE_URL}/export/hospital/doctor-detailed-incentive?${queryString}`
    let a = document.createElement("a")
    a.setAttribute("href", url)
    a.click()
}

onMounted(async () => {

    if(route.params.serviceId) {
        human_resource_id.value = route.params.serviceId;
    }

    loading.value = true

    const companyQuery = `?company_id=${companyId.value}&search_type=all`

    const businessRes = fetchBusinessList(companyQuery)

    await Promise.all([
        businessRes.then(({data}) => {
                if (data) business.value = data
        }),
        fetchCompanyInfo(companyId.value).then((res) => {
            companyInfo.value = res.data
        }),
        fetchContacts(companyQuery).then(({status, data}) => {
            if (status) contactList.value = data
        })
    ]).then(() => {
        loading.value = false;
    }).catch((err) => {
        loading.value = false
    });

    fetchHome().then(res => {
        if (res.data) {
            for (const value of Object.keys(res.data.hospital_invoice_types)) {
                billTypeList.value.push({
                    value: value,
                    label: res.data.hospital_invoice_types[value]
                })
            }
        }
    })

    getSalesReportGeneral()
})
</script>
